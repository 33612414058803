import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
export default function useVoucherAdd(emit) {
    const { t } = useI18nUtils();
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    
    const user_id = ref(router.currentRoute.params.user_id);
    const category_Data = ref([]);
    const items = ref([]);
    const blankData = {
      
      category_id:''
   
    }
  const currentPage = ref(1);
  const pageLength = ref(10);
  const Total = ref(0);
  const dir = ref(false);
  const toast = useToast()
    const refvoucherListTable = ref();

    const Data = ref(JSON.parse(JSON.stringify(blankData)));
    const tableColumns = [
      
      { key: 'no', label: '#', sortable: false },
      { key: 'title', label: t('list_service'), sortable: false },
      { key: 'credit', label:'Credit', sortable: false },
      
      { key: 'qty', label: 'จำนวน', sortable: false },
      { key: 'price', label: 'ราคา/หน่วย', sortable: false },
    


  ];
    const fetchCategoryData  = async () => { 
        category_Data.value  = [];
        await store.dispatch('app-voucher/fetchCategoryData',{
          branch_id:branch_id,
          is_active:1,
        })
        .then(response => {
          response.data.data.forEach((data) => {
            if(data.group_id == 1){category_Data.value.push(data)}
            else if(data.group_id == 2){type_Data.value.push(data)}
          });
        console.log('voucher fetchCategoryData value',category_Data.value);
    
        })
        .catch((error) => {
        console.log('error',error);
        })
    }
    
    fetchCategoryData();
    const onSubmit = async () => {
      
      let itms = items.value.map((value)=>{
         
            let itm = {
                id:value.id,
                types:'voucher',
                item_name: value.title,
                description:(value.remark?value.remark:'')+(value.num_exp_date?'<small class="text-danger">***Voucher มีอายุการใช้งาน '+value.num_exp_date+'</small>':''),
                qty:value.qty,
                price:value.price,
                discount:0,
                credit:value.credit,
                total:value.price*value.qty,
                category_name:value.category_name,
                category_id:value.category_id,
                voucher:0
                /*category_id:value.category_id,
                category_name:value.category_name,
                credit:value.credit*/
        
            }
            return itm;

          

      }).filter(i=>i.qty>0);
      //console.log('vouchers-add',itms);

   
      emit('refetch-data', itms)
      emit('update:is-voucher-form-sidebar-active', false)
      
    }
    /*watch([Data.value.category_id], () => {
      ChangeCategory()
    })*/
    const ChangeCategory = (event)=>{
     // refvoucherListTable.value.refresh()
     items.value  = [];
     
      fetchData();
    
     

    }
    const getData = ()=>{ 
      console.log('new');
      items.value  = [];
      Data.value = (JSON.parse(JSON.stringify(blankData)));
        fetchData();
 
     
    }
    const fetchData = async (ctx, callback) => {
      console.log('change',Data.value.category_id);
      const offset = pageLength.value*(currentPage.value-1);
      items.value  = [];
       store
        .dispatch('app-voucher/fetchData', {
          limit: pageLength.value,
          offset:offset,
          //keywords: searchQuery.value,
          category_id:Data.value.category_id,
         // type_id:selected_type.value,
          is_active:1,
          branch_id:branch_id,
          order_by:'voucher.id DESC'
       
        })
        .then(response => {


            items.value = response.data.data;
            if(items.value.length > 0){
              items.value.map((itm)=>{
                itm.qty = 0;
                return itm;

              });

            }
           
        //  console.log('voucher voucher value',Data.value);
      //  callback(response.data.data)
          const total = response.data.total?response.data.total: Data.value.length;
         
          Total.value = total
         
        })
        .catch((error) => {
          console.log('error',error);
          toast({
            component: ToastificationContent,
            props: {
              title: "Error fetching voucher' list",
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
  }

    return {
       
        t,
        onSubmit,
        ChangeCategory,
        category_Data,
        Data,
        tableColumns,
        items,
        refvoucherListTable,
        fetchData,
        getData
    }
}