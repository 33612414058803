import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import voucher from '@/router/routes/voucher'
import course from '@/router/routes/course'
export default function useReceiptForm() {
    const { t } = useI18nUtils();
    const toast = useToast()
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    
    const isVoucherFormSidebarActive = ref(false)
    const isCheckListFormSidebarActive = ref(false)
    
    
    const isVoucherUsedSidebarActive = ref(false)
    const isCourseAddSidebarActive = ref(false);
    const isCourseNumSidebarActive = ref(false);
    const isGatewayFormActive = ref(false);
    const isProductAddSidebarActive = ref(false);
    const isCourseSetAddSidebarActive = ref(false);
    const isDiscountItemSidebarActive = ref(false);
    
    const patient_id = ref(router.currentRoute.params.user_id);
    const opdcard_id = ref(router.currentRoute.params.opdcard_id);
    const id = ref(router.currentRoute.params.id);
    const opdcard_code = ref('');
    
    const patient = ref({});
    const refitemsListTable = ref(null);
    const items = ref([]);
    const itemData = ref({});
    const itemDiscount = ref({});
    const category_Data = ref([]);
    const modal_title = ref('');
    const vouchers = ref([]);
   // const total_items = ref(0);
    const payment = ref({
        total_items:0,
        vouchers:[],
        branch_id:branch_id,
        voucher_used:0,
        voucher_balance:0,
        discount_value:0,
        discount_type:1,
        tax_value:0,
        disposit:0.00,
        gateway_id:0,
        tax_type:0,
        price_total:0,
        total:0,
        patients_id:patient_id.value?patient_id.value:0,
        created_by:userStorage.id,
        opdcard_id:opdcard_id.value?opdcard_id.value:0
    });
    const discount_options = ref([
        { value: 1, text: 'บาท' },
        { value: 2, text: 'เปอร์เซนต์' }
    ]);
    const tax_options = ref([
        { value: 0, text: 'ไม่มีภาษี' },
        { value: 7, text: 'ภาษีมูลค่าเพิ่ม 7%' },
        { value: 10, text: 'ภาษีมูลค่าเพิ่ม 10%' }
    ]);
  
    const tableColumns = [
      
        { key: 'no', label: '#', sortable: false },
        { key: 'item_name', label: t('list_service'), sortable: false },
        { key: 'qty', label: 'จำนวน', sortable: false, class:"text-center" },
        { key: 'price', label: 'ราคา/หน่วย', sortable: false },
        { key: 'discount', label: 'ส่วนลด/หน่วย', sortable: false },
        { key: 'total', label: 'ราคารวม', sortable: false },
        { key: 'action', label: 'ตัวเลือก', sortable: false, class:"text-center" },
        { key: 'voucher', label: 'Voucher', sortable: false },


    ]
    const updateItem = async (item)=>{
        if(item.qty > 0){
            const response = await store.dispatch('app-receipt/updateReceiptItems',item);
            updateItems();
        }
       
    }
    const updateItems = async ()=>{
        payment.value.total_items = 0;
     
        items.value.map((v)=>{
            if(!v.voucher){
                v.voucher = 0;

            }
            if(v.discount > 0){
                let discount = v.discount;
                let total = v.price*v.qty;
                let discount_value = (v.price*v.qty)*(discount/100);
                v.total = total-discount_value-v.voucher;
                payment.value.total_items += v.total;

            }else{
                v.total = (v.price*v.qty)-v.voucher;
                payment.value.total_items += v.total;
            }
           
            
        });
        calPayment();

    }
    const updateCredit = async (data)=>{
      //   console.log('credit', items.value);
         payment.value.total_items = 0;
      
        await payment.value.vouchers.map(async (item,ind) => {
          //  console.log('vouchers-s',item);
                    const index = vouchers.value.findIndex(s => s.category_id === item.category_id)
                    payment.value.vouchers[ind].used = 0;
                    if(index > 0){
                       
                        payment.value.vouchers[ind].balance = vouchers.value[index].balance*1;
                    }else{
                        payment.value.vouchers[ind].balance = payment.value.vouchers[ind].balances;
                    }
                   
                  
                    //item.used = 0;
                    // /item.balance = vouchers.value[index].balance*1;
                
                
        });
       //console.log('vouchers',payment.value.vouchers);
       // console.log('total_items',payment.value.total_items);
        await items.value.map(async (item) => {
          
            item.voucher = item.voucher*1;
            console.log('vouchers', payment.value.vouchers)
            
            const index = payment.value.vouchers.findIndex(s => s.category_id === item.vcat_id)
            if(index > -1){
                payment.value.vouchers[index].used += item.voucher;
                payment.value.vouchers[index].balance = payment.value.vouchers[index].balance-item.voucher;
            }
               

                payment.value.total_items += (item.total*1);
                
                
            
        })
    
        calPayment();
       /* const index = payment.value.vouchers.findIndex(s => s.category_id === data.category_id)
        console.log('used',data);
        console.log('items', items.value);
    
        let credit = data.credit*1;
        //let used = payment.value.voucher_used+credit;
       // console.log('credit',credit);
       // console.log('used',used+credit );
        payment.value.vouchers[index].used += credit;
        console.log('used',  payment.value);
        payment.value.vouchers[index].balance -= credit;
        calPayment();*/

        
    }
    const getVoucher = async ()=>{
        let params = {
            patients_id:patient_id.value,
            receipt_id:id.value

        }
        //console.log('params',params);
        store.dispatch('app-receipt/fetchPatientReciptVoucherData',params).then(response=>{
            console.log('patients_vouchers',response.data.data);
            if(response.data.success){
                vouchers.value  = response.data.data;
                payment.value.voucher_balance = response.data.data?response.data.data[0].balance*1:0;
                payment.value.vouchers = response.data.data.map((value)=>{
                    value.used = 0;
                    value.balance = value.balance*1;
                    return value;

                });
               // console.log('payment',payment.value);
                //patient.value =response.data.data[0];
                
            }
            
          }).catch((err)=>{
            console.log('err',err);
           
      
          })
    }
    
    
    const calPayment = ()=>{
        let voucher_used = 0;
       /* payment.value.vouchers.forEach(voucher=>{
            if(voucher.used > 0){
                voucher_used += voucher.used

            }
        });*/
        //console.log('calpay',payment.value.discount_value);
       
        if(payment.value.discount_value > 0){
            if(payment.value.discount_type == 1){
                if(payment.value.discount_value >= payment.value.total_items){
                    payment.value.discount_value = payment.value.total_items?payment.value.total_items:0

                }
                payment.value.price_total =  payment.value.total_items-payment.value.discount_value;

            }else{
                if(payment.value.discount_value > 100){
                    payment.value.discount_value = 100;

                }
                const discount =  payment.value.total_items*(payment.value.discount_value/100);
                payment.value.price_total =  payment.value.total_items-discount;
            }

        }else{
            payment.value.price_total =  payment.value.total_items;

        }
       

        if(payment.value.tax_type > 0){
            payment.value.tax_value =  payment.value.price_total*(payment.value.tax_type/100);
            payment.value.total = payment.value.price_total+ payment.value.tax_value;

        }else{
            payment.value.total =  payment.value.price_total;

        }
        if(voucher_used > 0){
          //  payment.value.total -= voucher_used;
        }

        
        
        

    }
    
    const userData = async ()=>{
        store.dispatch('app-receipt/fetchPatientData',patient_id.value).then(response=>{
          
            if(response.data.success){
                patient.value =response.data.data[0];
                //console.log('patient',patient.value);
            }
            
          }).catch((err)=>{
            console.log('err',err);
           
      
          })
     /// const user =  await  store.dispatch('app-receipt/fetchPatientData',user_id.value);
    
    }
    const opdData = async ()=>{
        
        let resp = await store.dispatch('app-receipt/fetchOpdCardDetail',opdcard_id.value);
       
        if(resp.data.success){
            let opdcard = resp.data.data[0]
            console.log('opdData',opdcard);
            patient_id.value = opdcard.patient_id;
            payment.value.patients_id = opdcard.patient_id;
            opdcard_code.value = opdcard.code;
            userData();
            getVoucher();
 
        }
       
        
    }
    const receiptData = async ()=>{
        
        let resp = await store.dispatch('app-receipt/fetchDataDetail',id.value);
      //  console.log('resp-test',resp);
       
        if(resp.data.success){
            let receipt = resp.data.data;
            
           
           
            let items =  await  receipt.items.map((value,index)=>{
                
                let qty = value.qty?value.qty:1;
                let price = value.price?value.price:0;
                let itm = {
                    idIndex:value.id,
                    id:value.related_id,
                    types:value.types,
                    item_name: value.item_name,
                    description:(value.description),
                    qty:qty,
                    price:price,
                    credits:value.credits,
                    discount:value.discount*1,
                    total:value.total*1
            
                }
                console.log('receipt-data',itm);
                
                return itm
                //items.value.push(itm);
            });
            
            refetchData(items);
            opdcard_code.value = receipt.opd_code;
            patient_id.value = receipt.patients_id;
            userData();
            getVoucher();
            //refetchData(resp.data.data.items);
           /* let opdcard = resp.data.data[0]
            console.log('opdData',opdcard);
            patient_id.value = opdcard.patient_id;
            payment.value.patients_id = opdcard.patient_id;
            opdcard_code.value = opdcard.code;
            userData();
            getVoucher();*/
 
        }
       
        
    }
    const opdItems = async ()=>{
       let resData =  await store
            .dispatch('app-roomdetail/fetchOpdcardItemData', {
              opdcard_id:opdcard_id.value,
             
              
              branch_id:branch_id,
              order_by:'opdcard_item.id DESC'
          
    })
    if(resData.data.success){
        let itms = resData.data.data;
     let items =  await  itms.map((value,index)=>{
            let qty = value.qty?value.qty:1;
            let price = value.price?value.price:0;
            let itm = {
                id:value.id,
                types:'voucher',
                item_name: value.title,
                description:(value.course_title?value.course_title:''),
                credit:(value.credit?value.credit:0),
                qty:qty,
                price:price,
                discount:0,
                total:price*qty,
                voucher:0
        
            }
            
            if(value.course_id){
                itm.id = value.course_id;
                itm.types = 'course'
                itm.item_name = value.course_title

            }else if(value.meterial_id){
                itm.id = value.meterial_id;
                itm.types = 'meterial'
                itm.item_name = value.meterial_title
            }else if(value.medicine_id){
                itm.id = value.meterial_id;
                itm.types = 'medicine'
                itm.item_name = value.medicine_title
            }else if(value.checklist_id){
                itm.id = value.checklist_id;
                itm.types = 'checklist'
                itm.item_name = value.checklist_title
              
                itm.price = value.checklist_price
               itm.total = value.checklist_price*itm.qty

            }
            return itm
            //items.value.push(itm);
        });
        refetchData(items);

    }
    console.log('resData',resData);

    }
    if(opdcard_id.value > 0){
        opdData();
        opdItems();
      
    }else if(id.value > 0){
      receiptData();
        
    }else{
        getVoucher();
        userData();
    }
    
    const addItems = async ()=>{
        let data = {
            no:1,
            item_name:'',
            qty:2,
            price:100,
            discount:0,
            total:200

        }
        items.value.push(data);
    }
    const addItemData = async (value)=>{
        let data = {
            receipt_id:id.value,
            patients_id:patient_id.value,
            items:value
        }
       const response = await store.dispatch('app-receipt/addReceiptItems',data);
       items.value = [];
       receiptData();
      // refetchData(value);
    }
     
    const refetchData = async (value)=>{
        console.log('value-add',value);
   
        if(value.length > 0){
            let data = {
                receipt_id:id.value,
                items:value
            }
           // const response = store.dispatch('app-receipt/addReceiptItems',data);
           // receiptData();
           value.map((v)=>{
                
                if(v.qty > 0){
                    const index = items.value.findIndex(p => p.id === v.id && p.types===v.types);
                    if(index < 0){
                        items.value.push(v);
                       
                    }else{
                       items.value[index].qty += v.qty
                       items.value[index].total = items.value[index].qty*items.value[index].price;
                       

                    }
                   
                   
                    payment.value.total_items +=  v.total;
                }
                if(v.types == 'voucher' && v.qty > 0){
                    const index = payment.value.vouchers.findIndex(pay=>pay.voucher_id===v.id);
                    console.log('voucher-b',v);
                    if(index > -1){
                        payment.value.vouchers[index].balance += v.credit;

                    }else{
                        let itm = {
                            used :0,
                            voucher_id:v.id,
                            category_id:v.category_id,
                            category_name:v.category_name,
                            credit:v.credit,
                            balances :v.credit*1,
                            balance :v.credit*1
                        }
                        payment.value.vouchers.push(itm);
                        updateCredit(v);

                    }
                
                //    payment.value.vouchers = response.data.data.map((value)=>{
                //         value.used = 0;
                //         value.balance = value.balance*1;
                //         return value;
    
                //     });
                    
                }

            });
            calPayment();
            

        }
       
       
    }
    const removeItem = async (index)=>{
        
        console.log('items', items.value);
        let resp = await store.dispatch('app-receipt/deleteItemReceipt',items.value[index].idIndex);
        if(resp.data.success === true){
            if(items.value[index].types == 'voucher' ){
                const ind = payment.value.vouchers.findIndex(pay=>pay.voucher_id===items.value[index].id);
                if(ind > -1){
                    if(payment.value.vouchers[ind].balance === items.value[index].credit){
                        payment.value.vouchers.splice(ind, 1);
    
                    }else{
                        payment.value.vouchers[ind].balance -= items.value[index].creditd;
    
                    }
                   
                }
            
    
            }
            items.value.splice(index, 1);
            updateItems();

        }
        
    }
    const addVoucherUsed = (item)=>{
        itemData.value = item
        isVoucherUsedSidebarActive.value = true;

    }
    const openPopup = async (status_id)=>{
        payment.value.status_id = status_id;
        if(status_id == 3){
            modal_title.value = 'ชำระค่าบริการ'
        }
        if(status_id == 2){
            modal_title.value = 'วางมัดจำ'
        }
        isGatewayFormActive.value = true;

    }
    const updatePayment = async (value)=>{
        payment.value.payments = value.payments;
        if(payment.value.status_id == 2){
            payment.value.deposit = value.price;
        }
        onSubmit(payment.value.status_id);

    }
    const updatediscountItem = (item)=>{
        console.log('updatediscountItem');
        isDiscountItemSidebarActive.value =true;
        itemDiscount.value = item;

    }
    const onSubmit = async (status_id)=>{
      
        payment.value.status_id = status_id;
        payment.value.items = items.value;
        payment.value.id = id.value;
      
        const result = await  store.dispatch('app-receipt/fetchupdateReceipt',payment.value);
        if(result.data.success == true){
          
            let id = result.data.data.id;
          
           // router.push({ name: 'patients-read'});
           if(status_id == 1){
            router.push({ name: 'receipt-read', replace: true }).then(()=>{
                toast({
                    component: ToastificationContent,
                    props: {
                      title: "Create Receipt Success",
                      icon: 'AlertTriangleIcon',
                      variant: 'success',
                    },
                  })
            });
            
           }else{
            router.push({ name: 'receipt-confirm',params:{id:id}, replace: true }).then(()=>{
                toast({
                    component: ToastificationContent,
                    props: {
                      title: "Create Receipt Success",
                      icon: 'AlertTriangleIcon',
                      variant: 'success',
                    },
                  })
            });

           }
           
                //receipt-confirm
        }else{
            toast({
                component: ToastificationContent,
                props: {
                  title: "Create Receipt Error",
                  icon: 'AlertTriangleIcon',
                  variant: 'error',
                },
              })
        }
       
        console.log('result', result.data);

    }
   
    return {
        patient,
        refitemsListTable,
        tableColumns,
        items,
        addItems,
        isVoucherFormSidebarActive,
        isVoucherUsedSidebarActive,
        isCourseAddSidebarActive,
        isCourseNumSidebarActive,
        refetchData,
        t,
        payment,
        updateItems,
        removeItem,
        discount_options,
        calPayment,
        tax_options,
        updateCredit,
        addVoucherUsed,
        itemData,
        onSubmit,
        openPopup,
        opdcard_code,
        isGatewayFormActive,
        modal_title,
        updatePayment,
        isProductAddSidebarActive,
        isCourseSetAddSidebarActive,
        isDiscountItemSidebarActive,
        updatediscountItem,
        itemDiscount,
        isCheckListFormSidebarActive,
        addItemData,
        updateItem
       
    }
}