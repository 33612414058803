import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import router from '@/router'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
export default function useCourseSetAdd(emit) {
    const t = useI18nUtils;
    const userStorage = JSON.parse(localStorage.getItem('userData'));
    const branch_id = userStorage.branch_id;
    const blankData = {
      
        category_id:''
     
    }
    const Data = ref(JSON.parse(JSON.stringify(blankData)));
    const cats = ref([]);
    const courses = ref([]);
    const refcourseListTable = ref();
    const items = ref([]);
    const tableColumns = [
      
      { key: 'no', label: '#', sortable: false },
      { key: 'title', label: t('list_service'), sortable: false },
     
      
      { key: 'qty', label: 'จำนวน', sortable: false },
      { key: 'price', label: 'ราคา/หน่วย', sortable: false },
    


  ];
    const getData = ()=>{

    }
    const ChangeCategory = (event)=>{
        // refvoucherListTable.value.refresh()
        courses.value  = [];
        
        getCourses();
       
        
   
    }
    const getCoursesCategory = async ()=>{
        const categories = await store.dispatch('app-course/fetchCategoryData',{
            /*limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            category_id:selected_category.value,
            type_id:selected_type.value,*/
            is_active:1,
            //branch_id:branch_id,
         
        });
        if(categories.data.success){
            cats.value = categories.data.data;

        }
        console.log('categories',cats.value);
        
    }
    getCoursesCategory();
    const getCourses = async ()=>{
      
        //fetchCourseData
       const course = await store.dispatch('app-course/fetchCourseSetData',{
            /*limit: pageLength.value,
            offset:offset,
            keywords: searchQuery.value,
            group_id:selected_group.value,
            
            type_id:selected_type.value,*/
            category_id:Data.value.category_id,
            is_active:1,
           // branch_id:branch_id,
            order_by:'course_set.title ASC'
           });
          
           if(course.data.success){
                courses.value = course.data.data;
                if(courses.value.length > 0){
                    courses.value.map((itm)=>{
                      itm.qty = 0;
                      return itm;
      
                    });
      
                  }

            }
          
    }
   
    const onSubmit = async () => {
       
        let itms = courses.value.map((value)=>{
           
              let itm = {
                  id:value.id,
                  types:'course-set',
                  item_name: value.title,
                  expire_day:value.expire_day,
                  description:'',
                  qty:value.qty,
                  price:value.price,
                  discount:0,
                  total:value.price*value.qty
          
              }
              return itm;
  
            
  
        }).filter(i=>i.qty>0);
        //console.log('items',itms);
  
     
        emit('refetch-data', itms)
        emit('update:is-course-set-add-sidebar-active', false)
        
      }

    return {
        getData,
        cats,
        courses,
        tableColumns,
        refcourseListTable,
        items,
        Data,
        ChangeCategory,
        getCourses,
        onSubmit
    }
}